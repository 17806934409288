/* eslint-disable prefer-destructuring */
/* eslint-disable no-return-assign */
/**
 * Utility methods to be used for invoking API methods
 */

import Axios from 'axios';
import { parse } from 'qs';
import { getItem } from './localStorageControl';
import queryString from 'querystring';

export const hostname = () => {
  let hostUrl = '';
  switch (window.location.hostname) {
    case 'localhost':
      hostUrl = 'http://localhost:5001';
      // hostUrl = 'https://dts-test-api.upforks.com';

      break;
    default:
      hostUrl = 'https://dts-test-api.upforks.com';
      break;
  }
  return hostUrl;
};

export const getTenantDomain = () => {
  let tenantDomain = '';
  switch (window.location.hostname) {
    case 'localhost':
      return (tenantDomain = 'l95330');
    default:
      tenantDomain = window.location.hostname.split('.')[1];
      break;
  }
  return tenantDomain;
};

export const quickBooksRedirectUrl = 'https://app.sandbox.qbo.intuit.com';

const hostUrl = hostname();
export const makeUrl = ({ uri = '', pathParams, query, version }, host) =>
  `${host || hostUrl}${version}${uri
    .split('/')
    .map((param) => (param.charAt(0) === ':' ? encodeURI(pathParams[param.slice(1)]) : param))
    .join('/')}${query ? `?${queryString.stringify(query)}` : ''}`;

export const getDefaultHeaders = () => {
  return {
    Authorization: `Bearer ${getItem('access_token')}`,
    'Content-Type': 'application/json',
  };
};
function getPageQuery() {
  return parse(window.location.href && window.location.href.split('?')[1]);
}
/**
 * Returns true if the input apiResponse has errors.
 * @param {*} apiResponse
 */
export const hasErrors = (apiResponse) => {
  const { error } = apiResponse;
  if (error) {
    return true;
  }
  return false;
};
/**
 * Generic utility method that should be called when invoking any REST API
 *
 * This function streamlines the functionality to make api calls,
 * and carries easy management for controlling versions of the apis
 *
 * @since 1.0.0
 *
 * @todo all the incoming values for the APIParamaters.pathParams and APIParamaters.query
 * should be uri encoded.
 * @alias callAxios
 * @memberof apiUtils
 * @param {Object} APIParamaters - Set of objects required to make the api call.
 * @param {Object} APIParamaters.uriEndPoint - Endpoint object as described in apiEndPoints.js.
 * @param {String} APIParamaters.uriEndPoint.api - Path to your endpoint
 * @param {String} APIParamaters.uriEndPoint.method - POST/GET/PUT/DELETE etc.
 * @param {String} APIParamaters.uriEndPoint.version - Versioning of your api
 * @param {Object} APIParamaters.headerProps - Object of headers you want to pass.
 * @param {Object} APIParamaters.pathParams - Path parameters. Example :id in the path,
 * then pathParams object will be {id:value}.
 * @param {Object} APIParamaters.query - GET/POST/PUT/DELETE Endpoint.
 * @param {Object} APIParamaters.body - Body of the request.
 * @returns {Promise<object>} Body Data from the server.
 */
const callAxios = ({ uriEndPoint, pathParams, query, body, apiHostUrl, headerProps }) =>
  Axios({
    method: uriEndPoint.method,
    url: makeUrl({ ...uriEndPoint, pathParams, query }, apiHostUrl),
    headers: {
      ...getDefaultHeaders(),
      ...headerProps,
      'x-tenant-domain': getTenantDomain(),
    },
    data: body || {},
  });
/**
 * Extract the error messages from a failed API response.
 * @param {} apiResponse
 */
// const extractErrors = () => {};
/**
 * Generic utility method that should be called when invoking any REST API
 *
 * This function streamlines the functionality to make api calls,
 * and carries easy management for controlling versions of the apis
 *
 * @since 2.0.0
 *
 * @todo all the incoming values for the APIParamaters.pathParams and APIParamaters.query
 * should be uri encoded.
 * @alias callApi
 * @memberof apiUtils
 * @param {Object} APIParamaters - Set of objects required to make the api call.
 * @param {Object} APIParamaters.uriEndPoint - Endpoint object as described in apiEndPoints.js.
 * @param {String} APIParamaters.uriEndPoint.api - Path to your endpoint
 * @param {String} APIParamaters.uriEndPoint.method - POST/GET/PUT/DELETE etc.
 * @param {String} APIParamaters.uriEndPoint.version - Versioning of your api
 * @param {Object} APIParamaters.uriEndPoint.headerProps - Object of headers you want to pass.
 * @param {Object} APIParamaters.pathParams - Path parameters. Example :id in the path,
 * then pathParams object will be {id:value}.
 * @param {Object} APIParamaters.query - GET/POST/PUT/DELETE Endpoint.
 * @param {Object} APIParamaters.body - Body of the request.
 * @returns {Promise<object>} Body Data from the server.
 */
const clearLocalStroage = () => {
  localStorage.removeItem('apiToken');
  localStorage.removeItem('partyId');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('userId');
  localStorage.removeItem('apiToken');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('antd-pro-authority');
  localStorage.removeItem('timer');
};
export const callApi = (
  { uriEndPoint = { uri: '', method: '', version: '' }, pathParams, query, body, apiHostUrl, headerProps } = {
    headerProps: {},
  },
) =>
  new Promise((resolve, reject) => {
    callAxios({
      uriEndPoint,
      pathParams,
      query,
      body,
      apiHostUrl,
      headerProps,
    })
      .then((response) => {
        resolve(response.data);
        localStorage.setItem('timer', 1800);
      })
      .catch((err) => {
        if (!err.response) {
          if (!getPageQuery().redirect) {
            // window.location.href = '/login';
          }
          return;
        }
        if (err?.response?.status === 401) {
          const { redirect } = getPageQuery(); // Note: There may be security issues, please note
          if (window.location.pathname !== '/dashboard' && !redirect) {
            // window.location.href = '/dashboard';
          }
          clearLocalStroage();
          reject(err.response);
        }
        if (err?.response) {
          reject(err.response);
        }
      });
  });
