import {
  UilCreateDashboard,
  // UilDollarAlt,
  //   UilClipboardAlt,
  UilShieldCheck,
  //   UilDollarAlt,
} from '@iconscout/react-unicons';
import { Menu } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import propTypes from 'prop-types';

function MenuItems({ toggleCollapsed }) {
  const { t } = useTranslation();

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { topMenu } = useSelector((state) => {
    return {
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  });

  // const dispatch = useDispatch();

  const path = '/';

  const pathName = window.location.pathname;
  const pathArray = pathName || [];
  const mainPath = pathArray;
  const mainPathSplit = mainPath.split('/');
  // const currentUser = useSelector((payload) => payload.currentUser.data);
  // const roles = currentUser?.tenantRoles?.map((i) => i.role?.toLowerCase()?.replace(' ', ''));
  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 1 ? [mainPathSplit[1]] : 'dashboard'}`] : [],
  );

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const items = [
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}`}>
        {t('dashboard')}
      </NavLink>,
      'dashboard',
      !topMenu && <UilCreateDashboard />,
    ),

    // getItem(t('Donor'), 'collections', !topMenu && <UilShieldCheck />, [
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}donor/lobby/waiting`}>
    //       {t('Waiting in lobby')}
    //     </NavLink>,
    //     'Waiting',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}donor/house-client`}>
    //       {t('In-House Check-ins')}
    //     </NavLink>,
    //     'In-House',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}donor/network-client`}>
    //       {t('Network Check-ins')}
    //     </NavLink>,
    //     'Network',
    //     null,
    //   ),
    // ]),
    // getItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}clients`}>
    //     {t('Clients')}
    //   </NavLink>,
    //   'clients',
    //   !topMenu && <UilShieldCheck />,
    // ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}driver`}>
        {t('Driver')}
      </NavLink>,
      'driver',
      !topMenu && <UilShieldCheck />,
    ),

    // getItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}addUser`}>
    //     {t('Staff')}
    //   </NavLink>,
    //   'addUser',
    //   !topMenu && <UilShieldCheck />,
    // ),

    // getItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}network/client`}>
    //     {t('Network')}
    //   </NavLink>,
    //   'networkClient',
    //   !topMenu && <UilShieldCheck />,
    // ),
    // getItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}pool`}>
    //     {t('Random Pool')}
    //   </NavLink>,
    //   'randomPool',
    //   !topMenu && <UilShieldCheck />,
    // ),
    // getItem(
    //   <NavLink target="_blank" rel="noopener noreferrer" onClick={toggleCollapsed} to={`${path}donor/checkin`}>
    //     Donor Check-in
    //   </NavLink>,
    //   'donor',
    //   !topMenu && <UilShieldCheck />,
    // ),
    // getItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}sap`}>
    //     Sap
    //   </NavLink>,
    //   'sap',
    //   !topMenu && <UilShieldCheck />,
    // ),
    // getItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}cases`}>
    //     Cases
    //   </NavLink>,
    //   'cases',
    //   !topMenu && <UilShieldCheck />,
    // ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}driver/test/result`}>
        Test Result
      </NavLink>,
      'testResult',
      !topMenu && <UilShieldCheck />,
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}transactions`}>
        Transactions
      </NavLink>,
      'transactions',
      !topMenu && <UilShieldCheck />,
    ),
  ];

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1
                  ? 'dashboard'
                  : mainPathSplit.length === 2
                  ? mainPathSplit[1]
                  : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? [mainPathSplit[1]] : 'dashboard'}`] : []}
      overflowedIndicator={<UilEllipsisV />}
      openKeys={openKeys}
      // items={items?.filter((item) => {
      //   if (roles?.includes('tenantadmin')) return item;
      //   return item.key !== 'transactions';
      // })}
      items={items}
    />
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
