const { defaults } = require('./defaults');

export const authEndpoints = {
  getTenant: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v2,
      uri: '/tenant',
    },
  },
  getPreference: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: '/me/preference',
    },
  },
  updatePreference: {
    v1: {
      ...defaults.methods.PUT,
      ...defaults.versions.v1,
      uri: '/me/preference',
    },
  },
  forgotPassword: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: '/auth/forgotPassword',
    },
  },
  verifyOtp: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: '/auth/verifyOtp/:token',
    },
  },
  resetPassword: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: '/auth/resetpassword/:token',
    },
  },
};
